import type {
  Bands,
  CheckWildcardReponse,
  GenericObject,
  GetLightDomainsRequest,
  Scope,
  ScopeModule,
  ScopeModuleConfig,
  Scopes,
  ScopesRequestPayload,
  ScopeUser,
  Role,
  UpdateScopeModule,
  ScopeCreatePayload,
} from "@/typings";
import axios from "./axios";

export const getScopes = (partnerName: string, payload: ScopesRequestPayload) => {
  return axios.get<Scopes>(`/v1/partners/${partnerName}/scopes`, {
    params: payload,
  });
};

export const addScope = (payload: ScopeCreatePayload) => {
  return axios.post<string>("/v1/scope/scopes", payload);
};

export const getScopeDetails = (partnerName: string, scope: string) => {
  return axios.get<Scope>(`/v1/partners/${partnerName}/${scope}`);
};

export const getScope = (partnerName: string, scope: string) => {
  return axios.get<string | null>(`/v1/scope/scopes/${scope}`, {
    params: { partner: partnerName },
  });
};

export const updateScopeLicense = (payload: GenericObject) => {
  return axios.patch("/v1/scope/license", payload);
};

export const checkWildcard = (domain: string) => {
  return axios.get<CheckWildcardReponse>("/v1/asset/seed/wildcard", {
    params: { domain, partner: true },
  });
};

export const getLightDomains = ({ domain, countOnly = false }: GetLightDomainsRequest) => {
  return axios.get("/v1/light/domains", { params: { domain, countOnly, partner: true } });
};

export const updateScope = (scope: string, payload: GenericObject) =>
  axios.patch(`/v1/scope/manage?scope=${scope}`, payload);

export const getScopeUsers = (scope: string, partner: string) => {
  return axios.get<ScopeUser[]>("/v1/roles/users", { params: { scope, partner } });
};

export const getScopeModules = (
  scope: string,
  isCreate: boolean = false,
  partner_name: string | undefined = undefined,
) => {
  return axios.get<ScopeModuleConfig>(`/v1/scope/${scope}/modules`, {
    params: { is_create: isCreate, partner_name: partner_name },
  });
};

export const outscanCheckCredentials = (
  username: string,
  password: string,
  partner_name: string,
) => {
  return axios.post("/v1/outscan/check", { username, password, partner_name });
};

export const updateScopeModule = (scope: string, payload: UpdateScopeModule) => {
  return axios.post<ScopeModule>(`/v1/scope/${scope}/modules`, payload);
};

export const updateScopeUser = (payload: GenericObject) => axios.patch("/v2/user/users", payload);

export const getBands = (partner_name: string) =>
  axios.get<Bands>("/v1/bands", {
    params: { partner_name },
  });

export const getScopeRoles = (scope: string, partner: string) => {
  return axios.get<Role[]>("/v1/rbac/roles", { params: { scope, partner } });
};

export default {
  getScopes,
  addScope,
  getScopeDetails,
  getScope,
  updateScopeLicense,
  checkWildcard,
  getLightDomains,
  updateScope,
  getScopeUsers,
  getScopeModules,
  updateScopeModule,
  updateScopeUser,
};
